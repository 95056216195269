import { defineStore } from 'pinia'
import { useContentStore } from '~/composables/stores/useContentStore.js'
import { default as _has } from 'lodash/has'
import clonedeep from 'clone-deep'

export const useNotionRelationsStore = defineStore('notion_relations', () => {
  const contentStore = useContentStore()
  const allLoaded = ref({})
  const loadAllRequest = ref({})
  const abortControllers = ref({})
  const loadingItems = ref({})
  const cursor = ref({})
  // this.extraRecordsFetched = true

  const initForInput = (inputId) => {
    if (!contentStore.getByKey(inputId)) {
      contentStore.save({ id: inputId, relations: [] })
    }

    if (!_has(allLoaded.value, inputId)) {
      allLoaded.value[inputId] = false
    }

    if (!_has(loadAllRequest.value, inputId)) {
      loadAllRequest.value[inputId] = null
    }

    if (!_has(cursor.value, inputId)) {
      cursor.value[inputId] = null
    }

    if (!_has(abortControllers.value, inputId)) {
      abortControllers.value[inputId] = null
    }

    if (!_has(loadingItems.value, inputId)) {
      loadingItems.value[inputId] = false
    }

  }

  const loadAll = async (inputId, relationsFetchUrl, options) => {
    if (loadAllRequest.value[inputId]) {
      return loadAllRequest.value[inputId]
    }

    loadingItems.value[inputId] = true
    // eslint-disable-next-line
    loadAllRequest.value[inputId] = new Promise(async (resolve, reject) => {
      try {
        const data = await noteFormsFetch(relationsFetchUrl, options)
        const oldData = clonedeep(contentStore.getByKey(inputId)?.relations ?? [])
        if (oldData.length) {
          // Mix old relations and new ones
          data.forEach((item) => {
            if (!oldData.find((oldItem) => oldItem.id === item.id)) {
              oldData.push(item)
            }
          })
          contentStore.save({ id: inputId, relations: oldData })
        } else {
          contentStore.save({ id: inputId, relations: data })
        }
        loadingItems.value[inputId] = false
        loadAllRequest.value[inputId] = null
        allLoaded.value[inputId] = true
        resolve()
      } catch (err) {
        loadingItems.value[inputId] = false
        loadAllRequest.value[inputId] = null
        reject(err)
      }
    })
  }

  const createRelationItem = (inputId, item) => {
    const oldData = clonedeep(contentStore.getByKey(inputId)?.relations ?? [])
    oldData.push(item)
    contentStore.save({ id: inputId, relations: oldData })
  }
  return {
    ...contentStore,
    initForInput,
    loadAll,
    loadingItems,
    createRelationItem,
    allLoaded
  }
})
